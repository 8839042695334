import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import * as Sentry from '@sentry/react';

import Layout from '../components/ui/Layout';
import '../fragments.ts';
import PageSEO from '../components/PageSEO';

import '../styles/main.scss';
import { HeaderProps } from '../components/ui/Header';
import Section, { SectionProps } from '../components/ui/Section';
import { sendContactToKeap } from '../api';
import { FooterProps } from '../components/Footer';
import { LocalizedSEO } from '../fragments';
import { useTrackingData } from '../utils/hooks';

export const query = graphql`
  query GetPlansPageById($id: String!) {
    sanityLpViralSettings {
      header {
        signInButtonText
        anchorButtonText
      }
      footer {
        privacyPolicyPage {
          slug {
            current
          }
        }
      }
    }
    sanityLpViralPlansPage(id: { eq: $id }) {
      sections {
        title
        useTitleOnlyAsLabel
        description
        content {
          __typename
          ...Modules
        }
      }
      seo {
        ...SEO
      }
    }
  }
`;

interface PlansPageProps {
  data: {
    sanityLpViralSettings: {
      header: HeaderProps;
      footer: FooterProps;
    };
    sanityLpViralPlansPage: {
      sections: Array<SectionProps>;
      seo: LocalizedSEO;
    };
  };
}

const PlansPage = ({ data }: PlansPageProps): React.ReactElement => {
  const { sections, seo } = data.sanityLpViralPlansPage;
  const { header, footer } = data.sanityLpViralSettings;

  const trackingData = useTrackingData();

  useEffect(() => {
    const userEmailStored = window.localStorage.getItem('email');
    const keapTagIdsToAdd = window.localStorage.getItem('keapTagIdsToAdd');
    const emailSentToKeap = window.localStorage.getItem('emailSentToKeap') === 'true';
    if (userEmailStored && !emailSentToKeap) {
      (async () => {
        try {
          const success = await sendContactToKeap({
            email: userEmailStored,
            keapTagIdsToAdd: keapTagIdsToAdd?.split(','),
            trackingData,
          });
          if (success) {
            window.localStorage.setItem('emailSentToKeap', 'true');
          }
        } catch (err) {
          Sentry.captureException(err);
          console.error(err);
        }
      })();
    }
  }, []);

  return (
    <Layout
      privacyPolicyPage={footer.privacyPolicyPage}
      signInButtonText={header.signInButtonText}
      anchorButtonText={header.anchorButtonText}
    >
      <PageSEO defaultTitle={'Plans'} pageSEO={seo} />

      {sections.map((section, sectionIndex) => (
        <Section
          key={sectionIndex}
          {...section}
          sectionIndex={sectionIndex}
          lastSection={sectionIndex === sections.length - 1}
        ></Section>
      ))}
    </Layout>
  );
};

export default PlansPage;
